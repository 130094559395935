import React, { useState, useEffect } from "react";
import ChooseMethod from "../Modals/ChooseMethod";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../Header/user.css";
import AddAddress from "./../Modals/NewAddress";
import EditAddress from "./../Modals/NewAddress/EditAddress";
import MasterCard from "./../Modals/MasterCard/index";
import ChangePass from "./../Modals/ChangePass";
import Config from "./../../Config";
import Axios from "axios";
import DebitCard from "./DebitCard";
import { connect } from "react-redux";
import {
  forceRender,
  get_paymentId,
  setCurrentLocale,
  set_profile,
} from "../../../store/Actions/authActions";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import Header from "../Header";
import WalletStepII from "../Modals/WoshPayement/Step2";
import DeleteAccount from "./DeleteAccount";

const Profile = (props) => {
  // const { t } = useTranslation();
  const [profile, setProfile] = useState(null);
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [phone, setPhone] = useState(null)
  const [vat, setVat] = useState(null)
  const [companyName, setCompanyName] = useState(null)
  const [loading, setLoading] = useState(false)
  const lng = cookies.get("i18next") ? cookies.get("i18next") : "en";

  const { t } = useTranslation();
  const changeLang = async (lang) => {
    cookies.set("i18next", lang);
    props?.dispatch(
      setCurrentLocale({
        ...props?.authUser,
        user_locale: lang,
      })
    );
    await Axios({
      url: `${Config.authURL}/auth`,
      method: "PUT",
      data: {
        locale: lang === "du" ? "nl" : lang === "fn" ? "fr" : lang
      },
      headers: {
        uid: props?.authUser?.user_uid,
        client: props?.authUser?.user_client,
        "access-token": props?.authUser?.user_access_token,
      },
    })
      .then((response) => {
        const user = {
          user_uid: response.headers.uid,
          user_access_token: response.headers["access-token"],
          user_client: response.headers.client,
          user_id: response.data.data.id,
          user_name: response.data.data.first_name + " " + response.data.data.last_name,
        };
        props?.dispatch(
          forceRender(
            props?.render !== 0 ? props?.render + 1 : 1
          )
        );
        props?.dispatch(set_profile())

      })
      .catch((error) => {
        
      });
    window.location.reload();
  };



  const getProfiles = async () => {
    const uid = props?.authUser?.user_uid;
    const client = props?.authUser?.user_client;
    const accessToken = props?.authUser?.user_access_token;
    try {
      const response = await Axios({
        url: `${Config.apiURL}/customer/profiles`,
        method: "get",
        headers: {
          uid: `${uid}`,
          client: `${client}`,
          "access-token": `${accessToken}`,
        },
      });
      setProfile(response?.data?.profile);
      setFirstName(response?.data?.profile?.first_name)
      setLastName(response?.data?.profile?.last_name)
      setPhone(response?.data?.profile?.phone_number)
      setVat(response?.data?.profile?.vat)
      setCompanyName(response?.data?.profile?.company_name)
    } catch (err) { }
  };

  useEffect(() => {
    getProfiles();
  }, []);


  const handleSubmit = async () => {
    await Axios({
      url: `${Config.authURL}/auth`,
      method: "PUT",
      data: {
        first_name: firstName,
        last_name: lastName,
        phone_number: phone,
        company_name: companyName,
        vat: vat
      },
      headers: {
        uid: props?.authUser?.user_uid,
        client: props?.authUser?.user_client,
        "access-token": props?.authUser?.user_access_token,
      },
    })
      .then((response) => {
        // console.log("response from profile change", response);
        const user = {
          user_uid: response.headers.uid,
          user_access_token: response.headers["access-token"],
          user_client: response.headers.client,
          user_id: response.data.data.id,
          user_name: response.data.data.first_name + " " + response.data.data.last_name,
        };
        // console.log({ response });
        // this.setState((prev) => {
        //   return {
        //     ...prev,
        //     loading: false,
        //     success: true,
        //     msg: "okay",
        //   };
        // });
        // this.handleValidate();
        // this.props?.dispatch(setCurrentUser(user));
        props?.dispatch(
          forceRender(
            props?.render !== 0 ? props?.render + 1 : 1
          )
        );
        props?.dispatch(set_profile())

      })
      .catch((error) => {
        
      });
  };


  return (
    <div /*className={this.state.blur ? "modal_main" : null}*/>
      <div className="top_right">
        <img src="/assets/imgs/bubble_3.png" />
      </div>
      <div className="bottom_right">
        <img src="/assets/imgs/bubble_2.png" className="img-fluid" />
      </div>
      <div className="middle_left">
        <img src="/assets/imgs/bubble.png" />
      </div>
      {/* <Header isNav={true} /> */}
      <div className="scroll_tab_content_new_2">
        <div
          className="w_50 mx-auto tab-pane fade active show"
          id="edit_account"
          role="tabpanel"
          aria-labelledby="profile-tab"
        >
          <h2 className="text-center theme_color fw-bold mt-5">{t("profile")}</h2>
          {/* <div className="border_earn_credit rounded p-5 mb-3"> */}
          <div className="mt-2 w-75 mx-auto">
            <div className="border_earn_credit rounded py-5">
              <div className="d-flex align-items-center flex-column mb-5">
                <h6 className="theme_color mb-3 fw-bold">{t("USER ID")}</h6>
                <h4 className="w-50 rounded-pill bold text-center theme_bg white p-2 color_text_profile">{profile?.wosh_user_id}</h4>
              </div>
              <div id="login_form">
                <h6 className="text-center theme_color fw-bold mb-5">{t("Personal Details")}</h6>
                <div className="position-relative mb-4 form_input_style d-flex flex-row px-3 py-1 align-items-center">
                  <label className="position-absolute px-3 theme_bg white lable_style">
                    {t("First Name")}
                  </label>
                  <img alt="" src="../assets/imgs/account.png" width={14} />
                  <input
                    type="text"
                    className="form-control bg-transparent border-0 theme_color"
                    id="firstName"
                    name="firstName"
                    onChange={(event) => {
                      setFirstName(event.target.value)
                    }}
                    value={firstName}
                  />
                </div>
                <div className="position-relative mb-4 form_input_style d-flex flex-row px-3 py-1 align-items-center">
                  <label className="position-absolute px-3 theme_bg white lable_style">
                    {t("Last Name")}
                  </label>
                  <img alt="person" src="../assets/imgs/account.png" width={14} />
                  <input
                    type="text"
                    className="form-control bg-transparent border-0 theme_color"
                    id="lastName"
                    aria-describedby="emailHelp"
                    onChange={(event) => {
                      setLastName(event.target.value)
                    }}
                    value={lastName}
                  />
                </div>
                <div className="position-relative mb-4 form_input_style d-flex flex-row px-3 py-1 align-items-center">
                  <label className="position-absolute px-3 theme_bg white lable_style">
                    {t("Email")}
                  </label>
                  <i className="fa fa-envelope-o" aria-hidden="true" />
                  <input
                    readOnly="true"
                    type="email"
                    className="form-control bg-transparent border-0 theme_color"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder={profile ? profile.email : "your email "}
                  />
                </div>
                <div className="position-relative mb-4 form_input_style d-flex flex-row px-3 py-1 align-items-center">
                  <label className="position-absolute px-3 theme_bg white lable_style">
                    {t("phone")}
                  </label>
                  <img alt="person" src="../assets/imgs/telephone.png" width={14} />
                  <input
                    type="text"
                    className="form-control bg-transparent border-0 theme_color"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={phone}
                    onChange={(event) => {
                      setPhone(event.target.value)
                    }}
                  />
                </div> <div className="position-relative mb-4 form_input_style d-flex flex-row px-3 py-1 align-items-center">
                  <label className="position-absolute px-3 theme_bg white lable_style">
                    {t("VAT")}
                  </label>
                  <img alt="person" src="../assets/imgs/vat.png" width={14} />
                  <input
                    type="text"
                    className="form-control bg-transparent border-0 theme_color"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={vat}
                    onChange={(event) => {
                      setVat(event.target.value)
                    }}
                  />
                </div> <div className="position-relative mb-2 form_input_style d-flex flex-row px-3 py-1 align-items-center">
                  <label className="position-absolute px-3 theme_bg white lable_style">
                    {t("Company name")}
                  </label>
                  <img alt="person" src="../assets/imgs/company_img_1.png" width={14} />
                  <input
                    type="text"
                    className="form-control bg-transparent border-0 theme_color"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={companyName}
                    onChange={(event) => {
                      setCompanyName(event.target.value)
                    }}
                  />
                </div>
                <div
                  onClick={() => { handleSubmit() }}
                  className="text-end cursor_pointer mb-3"
                  disabled={(firstName === profile?.first_name && lastName === profile?.last_name && phone === profile?.phone_number && vat === profile?.vat && companyName === profile?.company_name)}
                >
                  <span className="theme_color text-decoration-underline cursor_pointer fw-bold">{t("Save")} <img src="./assets/imgs/save_icon.png" className="ms-1" width={15} /></span>
                </div>
                {/* <button
                  onClick={()=>{handleSubmit()}}
                  className="w-75 btn fill_btn my-3 ms-auto me-auto d-flex justify-content-center"
                  disabled = { (firstName === profile?.first_name && lastName === profile?.last_name && phone === profile?.phone_number && vat === profile?.vat && companyName === profile?.company_name)}
                >
                  {t("Save")}
                </button> */}
                <div className="row">
                  <h6 className="text-center theme_color fw-bold">{t("Language")}</h6>
                  <div className="col-md-4">
                    <button
                      onClick={() => changeLang("du")}
                      type="submit"
                      className={
                        lng === "du"
                          ? "w-100 btn font_in_profile fill_btn mt-3 ms-auto me-auto d-flex justify-content-center py-3"
                          : "w-100 btn font_in_profile border_btn mt-3 ms-auto me-auto d-flex justify-content-center py-3"
                      }
                    >
                      Nederlands
                    </button>
                  </div>
                  <div className="col-md-4">
                    <button
                      type="submit"
                      className={
                        lng === "en"
                          ? "w-100 btn font_in_profile fill_btn mt-3 ms-auto me-auto d-flex justify-content-center py-3"
                          : "w-100 btn font_in_profile border_btn mt-3 ms-auto me-auto d-flex justify-content-center py-3"
                      }
                      onClick={() => changeLang("en")}
                    >
                      English
                    </button>
                  </div>
                  <div className="col-md-4">
                    <button
                      onClick={() => changeLang("fn")}
                      type="submit"
                      className={
                        lng === "fn"
                          ? "w-100 btn font_in_profile fill_btn mt-3 ms-auto me-auto d-flex justify-content-center py-3"
                          : "w-100 btn font_in_profile border_btn mt-3 ms-auto me-auto d-flex justify-content-center py-3"
                      }
                    >
                      Français
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between mt-3">
              <a href="https://www.wosh.be/legal/terms-conditions"
                target="_blank"
                className="theme_color">{t("Terms & Conditions")}</a>
              <a
                href=""
                className="theme_color"
                data-bs-toggle="modal"
                data-bs-target="#delete_modal"
              >{t("Delete Account")}
              </a>
              <DeleteAccount />
              {/* <a href="" className="theme_color">Delete account</a> */}
            </div>
          </div>

        </div>
      </div>
      {/* <TabContent /> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  cards: state?.auth?.paymentId,
  render: state?.auth?.render,
  authUser: state?.auth?.user,
});
export default connect(mapStateToProps)(Profile);
